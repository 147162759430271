.root {
  margin: 0;

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.messageItem {
  margin-bottom: 20px;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  @media (--viewportSmartphone) {
    margin-bottom: 17px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 433px;
}

.message {
  display: flex;
  flex-direction: row;
}

.senderMessageContentWrapper {
  margin-left: 65px;
}

.ownMessageContentWrapper {
  margin-right: 65px;
}

.ownMessage {
  float: right;
}

.avatar {
  flex-shrink: 0;
  margin: 0;
}

.senderAvatar,
.ownAvatar {
  position: absolute;
  top: 50%;

  transform: translate(-50%, -50%);
}

.senderAvatar {
  left: -40px;
}

.ownAvatar {
  right: -75px;
}

.ownMessageContentWrapper {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.messageContent,
.ownMessageContent {
  /* @apply --marketplaceMessageFontStyles; */
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightLight);
  font-size: 16px;
  line-height: 24px;

  display: inline-block;
  position: relative;
  margin: 0;
  padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;

  @media (--viewportSmartphone) {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0;
  }
}

.messageContent {
  flex: 1;
  background-color: var(--matterColorLight);
}

.ownMessageContent {
  background-color: var(--nomadyLightGreen);
  color: var(--matterColorDark);
  float: right;
}

.messageDate,
.ownMessageDate {
  /* @apply --marketplaceMessageDateFontStyles; */
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightLight);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.1px;

  @media (--viewportSmartphone) {
    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 24px;
  }

  margin: 11px 65px 0 0;
  color: var(--matterColorAnti);

  @media (--viewportSmartphone) {
    margin: 6px 65px 0 0;
  }
}

.ownMessageDate {
  text-align: right;
}

.transitionItem {
  margin-bottom: 18px;


  @media (--viewportSmartphone) {
    margin-bottom: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportSmartphone) {
    padding: 0;
  }
}

.transitionContent {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 18px;

  @media (--viewportSmartphone) {
    font-size: 20px;
    line-height: 32px;
  }

  margin: 0;
}

.transitionDate {
  /* @apply --marketplaceMessageDateFontStyles; */
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightLight);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.1px;

  @media (--viewportSmartphone) {
    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 24px;
  }

  color: var(--matterColorAnti);
  margin: 7px 0 0 0;

  @media (--viewportSmartphone) {
    margin: -1px 0 1px 0;
  }
}

.bullet {
  margin-right: 6px;
}

.reviewContent {
  /* @apply --marketplaceH4FontStyles; */
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportSmartphone) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }

  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

  @media (--viewportSmartphone) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportSmartphone) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportSmartphone) {
    margin-bottom: 0;
  }
}

.showOlderButton {
}
