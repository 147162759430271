@import '../../marketplace.module.scss';

.root {
}

.bottomWrapper {
  margin-top: 46px;

  @media (--viewportSmartphone) {
    margin-top: 96px;
  }
}

.checkbox {
  display: none;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #8E8E8E;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportSmartphone) {
    margin: 24px 0;
  }
}

.languageTitleWrapper {
  margin-bottom: 24px;
}
