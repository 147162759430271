@import './marketplaceFonts.module.scss';

$vpSmartphoneMaxWidth: 100%; /* used to be Medium */
$vpNetbookMaxWidth: 1024px; /* used to be Large */
$vpLaptopMaxWidth: 1140px; /* new */
$vpScreenMaxWidth: 1200px; /* new */
$vpBigscreenMaxWidth: 1400px; /* new */
$vpHDMaxWidth: 1660px; /* used to be XLarge */
$vpUWHDMaxWidth: 2300px; /* new */
$vpUWQHDMaxWidth: 3200px; /* new */
$vpUHDMaxWidth: 3500px; /* new */

/* ================ Custom media queries ================ */

$viewportMobile: 550px; /* used to be Small */
$viewportSmartphone: 768px; /* used to be Medium */
$viewportTablet: 992px; /* new */
$viewportNetbook: 1024px; /* used to be Large */
$viewportLaptop: 1240px; /* new */
$viewportScreen: 1440px; /* new */
$viewportBigscreen: 1660px; /* new */
$viewportUltrascreen: 1800px; /* new */
$viewportHD: 1921px; /* used to be XLarge */
$viewportUWHD: 2560px; /* new */
$viewportUWQHD: 3440px; /* new */
$viewportUHD: 3840px; /* new */
$viewport4K: 4096px; /* new */

@custom-media --viewportMobile (min-width: #{$viewportMobile}); /* used to be Small */
@custom-media --viewportSmartphone (min-width: #{$viewportSmartphone}); /* used to be Medium */
@custom-media --viewportTablet (min-width: #{$viewportTablet}); /* new */
@custom-media --viewportNetbook (min-width: #{$viewportNetbook}); /* used to be Large */
@custom-media --viewportLaptop (min-width: #{$viewportLaptop}); /* new */
@custom-media --viewportScreen (min-width: #{$viewportScreen}); /* new */
@custom-media --viewportBigscreen (min-width: #{$viewportBigscreen}); /* new */
@custom-media --viewportUltrascreen (min-width: #{$viewportUltrascreen}); /* new */
@custom-media --viewportHD (min-width: #{$viewportHD}); /* used to be XLarge */
@custom-media --viewportUWHD (min-width: #{$viewportUWHD}); /* new */
@custom-media --viewportUWQHD (min-width: #{$viewportUWQHD}); /* new */
@custom-media --viewportUHD (min-width: #{$viewportUHD}); /* new */
@custom-media --viewport4K (min-width: #{$viewport4K}); /* new */

@custom-media --viewportSmartphoneMax (max-width: #{$viewportSmartphone}); /* used to be Medium */
@custom-media --viewportTabletMax (max-width: #{$viewportTablet}); /* new */
@custom-media --viewportNetbookMax (max-width: #{$viewportNetbook}); /* used to be Large */
@custom-media --viewportLaptopMax (max-width: #{$viewportLaptop}); /* new */

/* ================ Custom properties aka variables ================ */
:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* ================ Colors ================ */

  --nomadyRed: #e13f32;
  --marketplaceColor: #2ecc71;
  --marketplaceColorLight: #a3b69a;
  --marketplaceColorDark: #2a4f38;
  --nomadyGray: #858585;
  --nomadyDarkGrey: #4a4a4a;
  --nomadyLightGrey: #b2b2b2;
  --nomadyLightGreen: #a4f4c5;
  --nomadyBlueGrey: #78909c;
  --nomadyOrange: #ffaa00;

  --successColor: #2ecc71;
  --successColorDark: #239954;
  --successColorLight: #f0fff6;
  --failColor: #ff0000;
  --failColorLight: #fff0f0;
  --attentionColor: #ffaa00;
  --attentionColorLight: #fff7f0;
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);

  --matterColorDark: #000000;
  --matterColor: #4a4a4a;
  --matterColorInactive: #ababab;
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #c9c9c9;
  --matterColorBright: #f2f2f2;
  --matterColorLight: #ffffff;

  --backgroundColor: #f5f5f5;

  --listingPageBrowseUnit: var(--matterColorInactive);

  --leftSettingsColumnWidth: 434px;
  --leftSettingsColumnBackground: var(--backgroundColor);
  --rightSettingsColumnBackground: var(--backgroundColor);

  /* ================ Spacing unites ================ */

  /* Grid variables */
  --gridGutter: 15px;
  --gridGutterNegative: -15px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;

  /* ================ Border radius ================ */

  --borderRadius: 2px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar heights ================ */

  --topbarHeight: 85px;
  --topbarHeightDesktop: 90px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;


  /* ================ Clearfix solution ================ */

  --alert: {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  --alertWarning: {
    @apply --alert;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  --marketplaceLinkStyles: {
    /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;
    cursor: pointer;
  }

  /* ================ Buttons ================ */

  --marketplaceButtonFontStyles: {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightLight);
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.3px;

    @media (--viewportMedium) {
      /* TODO: Make sure button text aligns with the baseline */
    }
  }
  --marketplaceButtonStyles {
    @apply --marketplaceButtonFontStyles;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 65px;    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 10px 0;

    /* Borders */
    border: none;
    border-radius: 32.5px;

    /* Colors */
    background-color: var(--matterColorDark);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 65px;
    }
  }
  --marketplaceButtonStylesPrimary {
    @apply --marketplaceButtonStyles;

    background-color: var(--successColorDark);
    color: var(--matterColorLight);

    &:hover,
    &:focus {
      background-color: var(--successColor);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
    }
  }
  --marketplaceButtonStylesSecondary {
    @apply --marketplaceButtonStyles;

    background-color: var(--matterColorLight);
    color: var(--matterColor);

    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
    padding: 18px 0 0 0;

    /* border-width: 1px;
    border-style: solid;
    border-color: var(--matterColorNegative); */

    &:hover,
    &:focus {
      background-color: var(--matterColorLight);
      /* border-color: var(--matterColorAnti); */
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      /* border-color: var(--matterColorNegative); */
    }
  }
  --marketplaceFilterOverrideButton: {
    display: inline-block;
    padding: 15px 20px;
    width: auto;
    height: auto;
    min-height: 0;
    position: relative;
  }


  /* ================ Inputs ================ */

  --marketplaceInputStyles: {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;
    
    @apply --marketplaceDefaultFontStyles;

    /* Borders */
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--marketplaceColor);
    border-radius: 0;

    &::placeholder {
      color: var(--matterColorAnti);
    }

    /* Effects */
    outline: none;
    @media (--viewportMedium) {
      padding: 4px 0 10px 0;
    }
  }

  --marketplaceSelectStyles: {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;

    /* Unset user agent styles */
    appearance: none;

    /* Borders */
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--marketplaceColor);
    border-radius: 0;

    /* Background */
    background-size: 20px 20px;
    background-position: center right;

    /* Effects */

    cursor: pointer;
    transition: border-bottom-color var(--transitionStyle);

    @media (--viewportMedium) {
      padding: 4px 0 10px 0;
    }
  }

  /* ================ Modals ================ */

  --marketplaceModalRootStyles {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
    flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
    display: flex;

    justify-content: center;
    align-items: flex-start;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
    }
  }


  --marketplaceContentBaseStyles {
    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;

    margin: 40px 0 130px 0;
    flex-basis: 480px;
    border-radius: 10px;

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      /* min-height: 568px; */
      padding: var(--modalPaddingMedium);
      /* margin-top: 12.5vh; */
      /* margin-bottom: 12.5vh; */
      border-bottom: 8px solid var(--marketplaceColor);
    }
  }

  --marketplaceModalBaseStyles {
    /* Create context so the close button can be positioned within it */
    position: relative;
    flex-grow: 0;
    @apply --marketplaceContentBaseStyles;
  }

  --marketplaceModalInMobileBaseStyles {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: 0;

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      height: 100%;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
  }

  /* Modal title */

  --marketplaceModalTitleStyles {
    font-weight: var(--fontWeightSemiBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightSemiBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
  }

  /* Modal message */

  --marketplaceModalParagraphStyles {
    margin: 18px 0 0 0;

    @media (--viewportMedium) {
      margin: 24px 0 0 0;
    }
  }

  /* Bottom wrapper for the modal */

  --marketplaceModalBottomWrapper {
    text-align: center;
    margin-top: 60px;
    align-self: stretch;

    @media (--viewportMedium) {
      margin-top: 96px;
    }
  }

  --marketplaceModalHelperText {
    @apply --marketplaceH5FontStyles;
    color: var(--matterColorAnti);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
  }

  --marketplaceModalHelperLink {
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
  }

  /* Modal Close icon */

  --marketplaceModalCloseStyles {
    /* Position inside modal */
    position: fixed;
    top: 0;
    right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
    z-index: calc(var(--zIndexModal) + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
    display: flex;
    align-items: flex-start;
    width: auto;

    /* Sizing (touch area) */
    padding: 24px;
    margin: 0;
    border: 0;

    @media (--viewportMedium) {
      padding: 27px 30px;
      position: absolute;
    }

    /* Colors */
    background-color: transparent;
    color: var(--matterColor);

    cursor: pointer;

    &:enabled:hover {
      background-color: transparent;
      color: var(--matterColorDark);
      box-shadow: none;
      text-decoration: none;
    }
    &:enabled:active {
      background-color: transparent;
      color: var(--matterColorDark);
    }
    &:disabled {
      background-color: transparent;
    }
  }

  --marketplaceModalIconStyles {
    height: 48px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      height: 64px;
      margin-bottom: 23px;
    }
  }

  --marketplaceModalCloseText {
    /* Font */
    @apply --marketplaceH6FontStyles;
    margin: -2.5px 0 0 0;

    @media (--viewportMedium) {
      margin-top: -1px;
    }
  }

  --marketplaceModalCloseIcon {
    display: inline-block;
    margin-left: 8px;
    padding: 2px 0 4px;
    box-sizing: content-box;
  }

  --marketplaceModalErrorStyles {
    @apply --marketplaceH4FontStyles;
    margin-top: 24px;
    color: var(--failColor);
  }

  --marketplaceModalHighlightEmail {
    font-weight: var(--fontWeightBold);
  }

  /* ================ Panels ================ */

  --EditListingForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportSmartphone) {
      margin-bottom: 32px;
    }
  }

  --columnPanelLeft: {
    /* Layout */
    display: flex;
    flex-direction: row;
    padding: 0;
    flex-shrink: 0;
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowLight);
    border-top: 1px solid var(--matterColorNegative);
    overflow-x: scroll;

    @media (--viewportNetbook) {
      padding: 230px 100px 30px 30px;
      flex-direction: column;
      flex-shrink: initial;
      flex-basis: 0;
      align-items: flex-end;
      text-align: left;
      justify-content: start;
      min-width: var(--leftSettingsColumnWidth);
      overflow-x: auto;
      background-color: var(--leftSettingsColumnBackground);
      box-shadow: none;
      border-top: none;
      width: min-content;
    }

    @media (--viewportNetbookWithPaddings) {
      padding: 128px 0 82px calc((100% - 1056px) / 2);
    }
  }

  --columnPanelRight: {
    flex-grow: 1;

    @media (--viewportNetbook) {
      padding: 73px 100px;
      max-width: 100%;
      flex-grow: initial;
      /* padding: 0; */
      min-width: 0;
      flex-basis: 0;
      flex-grow: 2.4;
      border-left: 1px solid var(--matterColorNegative);
      background-color: var(--rightSettingsColumnBackground);
    }
  }

  /* ================ Grid ================ */

  --gridRow: {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    margin-right: var(--gridGutterNegative);
    margin-left: var(--gridGutterNegative);
  }

  --gridRowBare: {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    margin-right: 0;
    margin-left: 0;
  }

  --gridValignStart: {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    align-items: flex-start !important;
  }

  --gridValignCenter: {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    align-items: center !important;
  }

  --gridValignEnd: {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
    align-items: flex-end !important;
  }

  --gridHalignStart: {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
    justify-content: flex-start !important;
  }

  --gridHalignCenter: {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
  }

  --gridHalignEnd: {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    justify-content: flex-end !important;
  }

  --gridHalignAround: {
    -ms-flex-pack: distribute !important;
    -webkit-justify-content: space-around !important;
    justify-content: space-around !important;
  }

  --gridHalignBetween: {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    justify-content: space-between !important;
  }

  --gridColBase: {
    width: 100%;
    padding-right: var(--gridGutter);
    padding-left: var(--gridGutter);
  }

  --gridCol: {
    @apply --gridColBase;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  --gridColAuto: {
    @apply --gridColBase;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  --gridCol1 {
    @apply --gridColBase;
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  --gridCol2 {
    @apply --gridColBase;
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  --gridCol3 {
    @apply --gridColBase;
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  --gridCol4 {
    @apply --gridColBase;
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  --gridCol5 {
    @apply --gridColBase;
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  --gridCol6 {
    @apply --gridColBase;
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  --gridCol7 {
    @apply --gridColBase;
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  --gridCol8 {
    @apply --gridColBase;
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  --gridCol9 {
    @apply --gridColBase;
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  --gridCol10 {
    @apply --gridColBase;
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  --gridCol11 {
    @apply --gridColBase;
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  --gridCol12 {
    @apply --gridColBase;
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* ================ Container max widths ================ */

  /* THERE ARE STATIC VALUES IN THIS APP THAT NEED TO BE SYNCED IF BELOW VALUES ARE CHANGED */
  /* Search "[static-max-widths-here]" for those values */
  --vpSmartphoneMaxWidth: #{$vpSmartphoneMaxWidth}; /* used to be Medium */
  --vpNetbookMaxWidth: #{$vpNetbookMaxWidth}; /* used to be Large */
  --vpLaptopMaxWidth: #{$vpLaptopMaxWidth}; /* new */
  --vpScreenMaxWidth: #{$vpScreenMaxWidth}; /* new */
  --vpBigscreenMaxWidth: #{$vpBigscreenMaxWidth}; /* new */
  --vpHDMaxWidth: #{$vpHDMaxWidth}; /* used to be XLarge */
  --vpUWHDMaxWidth: #{$vpUWHDMaxWidth}; /* new */
  --vpUWQHDMaxWidth: #{$vpUWQHDMaxWidth}; /* new */
  --vpUHDMaxWidth: #{$vpUHDMaxWidth}; /* new */

  --marketplaceGridContainer: {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: var(--vpSmartphoneMaxWidth);

    @media (--viewportNetbook) {
      max-width: var(--vpNetbookMaxWidth);
    }

    @media (--viewportLaptop) {
      max-width: var(--vpLaptopMaxWidth);
    }

    @media (--viewportScreen) {
      max-width: var(--vpScreenMaxWidth);
    }

    @media (--viewportBigscreen) {
      max-width: var(--vpBigscreenMaxWidth);
    }

    @media (--viewportUltrascreen) {
      max-width: var(--vpHDMaxWidth);
    }

    @media (--viewportHD) {
      max-width: var(--vpHDMaxWidth);
    }

    @media (--viewportUWHD) {
      max-width: var(--vpUWHDMaxWidth);
    }

    @media (--viewportUWQHD) {
      max-width: var(--vpUWQHDMaxWidth);
    }

    @media (--viewportUHD) {
      max-width: var(--vpUHDMaxWidth);
    }

    @media (--viewport4K) {
      max-width: var(--vp4KMaxWidth);
    }
  }
}
