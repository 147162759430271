@import './marketplace.module.scss';

// Default Breakpoints

$mobile: --viewportMobile; // 550px
$smartphone: --viewportSmartphone; // 992px
$tablet: --viewportTablet; // 992px
$netbook: --viewportNetbook; // 1024px
$laptop: --viewportLaptop; // 1240px
$screen: --viewportScreen; // 1440px
$bigscreen: --viewportBigscreen; // 1440px
$HD: --viewportHD; // 1921px

.gridContainer {
  @apply --marketplaceGridContainer;
}

.gridFluidContainer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-Auto,
.colScreen,
.colScreen1,
.colScreen2,
.colScreen3,
.colScreen4,
.colScreen5,
.colScreen6,
.colScreen7,
.colScreen8,
.colScreen9,
.colScreen10,
.colScreen11,
.colScreen12,
.colScreenAuto,
.colLaptop,
.colLaptop1,
.colLaptop2,
.colLaptop3,
.colLaptop4,
.colLaptop5,
.colLaptop6,
.colLaptop7,
.colLaptop8,
.colLaptop9,
.colLaptop10,
.colLaptop11,
.colLaptop12,
.colLaptopAuto,
.colTablet,
.colTablet1,
.colTablet2,
.colTablet3,
.colTablet4,
.colTablet5,
.colTablet6,
.colTablet7,
.colTablet8,
.colTablet9,
.colTablet10,
.colTablet11,
.colTablet12,
.colTabletAuto,
.colSmartphone,
.colSmartphone1,
.colSmartphone2,
.colSmartphone3,
.colSmartphone4,
.colSmartphone5,
.colSmartphone6,
.colSmartphone7,
.colSmartphone8,
.colSmartphone9,
.colSmartphone10,
.colSmartphone11,
.colSmartphone12,
.colSmartphoneAuto,
.colMobile,
.colMobile1,
.colMobile2,
.colMobile3,
.colMobile4,
.colMobile5,
.colMobile6,
.colMobile7,
.colMobile8,
.colMobile9,
.colMobile10,
.colMobile11,
.colMobile12,
.colMobileAuto,
.colHD,
.colHD1,
.colHD2,
.colHD3,
.colHD4,
.colHD5,
.colHD6,
.colHD7,
.colHD8,
.colHD9,
.colHD10,
.colHD11,
.colHD12,
.colHDAuto,
.colBigscreen,
.colBigscreen1,
.colBigscreen2,
.colBigscreen3,
.colBigscreen4,
.colBigscreen5,
.colBigscreen6,
.colBigscreen7,
.colBigscreen8,
.colBigscreen9,
.colBigscreen10,
.colBigscreen11,
.colBigscreen12,
.colBigscreenAuto,
.colNetbook,
.colNetbook1,
.colNetbook2,
.colNetbook3,
.colNetbook4,
.colNetbook5,
.colNetbook6,
.colNetbook7,
.colNetbook8,
.colNetbook9,
.colNetbook10,
.colNetbook11,
.colNetbook12,
.colNetbookAuto,
.col-xxxl,
.col-xxxl-1,
.col-xxxl-2,
.col-xxxl-3,
.col-xxxl-4,
.col-xxxl-5,
.col-xxxl-6,
.col-xxxl-7,
.col-xxxl-8,
.col-xxxl-9,
.col-xxxl-10,
.col-xxxl-11,
.col-xxxl-12,
.col-xxxl-Auto {
  @apply --gridCol12;
}

// col Mobile - Laptop - Tablet - Desktop

.colAuto {
  @apply --gridColAuto;
}

.col {
  @apply --gridCol;
}

// col 1 - 12 | Mobile - Tablet - Netbook - Laptop - Screen -  HD

$mobileClass: colMobile !default;
$smartphoneClass: colSmartphone !default;
$tabletClass: colTablet !default;
$netbookClass: colNetbook !default;
$laptopClass: colLaptop !default;
$screenClass: colScreen !default;
$bigscreenClass: colBigscreen !default;
$HDClass: colHD !default;

@for $i from 1 through 12 {
  .col#{$i} {
    @apply --gridCol#{$i};
  }
}

@for $i from 1 through 12 {
  .#{$mobileClass}Auto {
    @media (#{$mobile}) {
      @apply --gridColAuto;
    }
  }
  .#{$mobileClass}#{$i} {
    @media (#{$mobile}) {
      @apply --gridCol#{$i};
    }
  }
}

@for $i from 1 through 12 {
  .#{$smartphoneClass}Auto {
    @media (#{$smartphone}) {
      @apply --gridColAuto;
    }
  }
  .#{$smartphoneClass}#{$i} {
    @media (#{$smartphone}) {
      @apply --gridCol#{$i};
    }
  }
}

@for $i from 1 through 12 {
  .#{$tabletClass}Auto {
    @media (#{$tablet}) {
      @apply --gridColAuto;
    }
  }
  .#{$tabletClass}#{$i} {
    @media (#{$tablet}) {
      @apply --gridCol#{$i};
    }
  }
}

@for $i from 1 through 12 {
  .#{$netbookClass}Auto {
    @media (#{$netbook}) {
      @apply --gridColAuto;
    }
  }
  .#{$netbookClass}#{$i} {
    @media (#{$netbook}) {
      @apply --gridCol#{$i};
    }
  }
}

@for $i from 1 through 12 {
  .#{$laptopClass}Auto {
    @media (#{$laptop}) {
      @apply --gridColAuto;
    }
  }
  .#{$laptopClass}#{$i} {
    @media (#{$laptop}) {
      @apply --gridCol#{$i};
    }
  }
}

@for $i from 1 through 12 {
  .#{$screenClass}Auto {
    @media (#{$screen}) {
      @apply --gridColAuto;
    }
  }
  .#{$screenClass}#{$i} {
    @media (#{$screen}) {
      @apply --gridCol#{$i};
    }
  }
}

@for $i from 1 through 12 {
  .#{$bigscreenClass}Auto {
    @media (#{$bigscreen}) {
      @apply --gridColAuto;
    }
  }
  .#{$bigscreenClass}#{$i} {
    @media (#{$bigscreen}) {
      @apply --gridCol#{$i};
    }
  }
}

@for $i from 1 through 12 {
  .#{$HDClass}Auto {
    @media (#{$HD}) {
      @apply --gridColAuto;
    }
  }
  .#{$HDClass}#{$i} {
    @media (#{$HD}) {
      @apply --gridCol#{$i};
    }
  }
}

// row defaults

.row {
  @apply --gridRow;
}

.rowBare {
  @apply --gridRowBare;
}

.rowReverse {
  flex-direction: row-reverse;
}

// Content positioning

// General:
.alignItemsCenter {
  @apply --gridValignCenter;
}
.alignItemsStart {
  @apply --gridValignStart;
}
.alignItemsEnd {
  @apply --gridValignEnd;
}

// Mobile:
.alignItemsCenterMobile {
  @media (#{$mobile}) {
    @apply --gridValignCenter;
  }
}
.alignItemsStartMobile {
  @media (#{$mobile}) {
    @apply --gridValignStart;
  }
}
.alignItemsEndMobile {
  @media (#{$mobile}) {
    @apply --gridValignEnd;
  }
}

// Smartphone:
.alignItemsCenterSmartphone {
  @media (#{$smartphone}) {
    @apply --gridValignCenter;
  }
}
.alignItemsStartSmartphone {
  @media (#{$smartphone}) {
    @apply --gridValignStart;
  }
}
.alignItemsEndSmartphone {
  @media (#{$smartphone}) {
    @apply --gridValignEnd;
  }
}

// Tablet:
.alignItemsCenterTablet {
  @media (#{$tablet}) {
    @apply --gridValignCenter;
  }
}
.alignItemsStartTablet {
  @media (#{$tablet}) {
    @apply --gridValignStart;
  }
}
.alignItemsEndTablet {
  @media (#{$tablet}) {
    @apply --gridValignEnd;
  }
}

// Netbook:
.alignItemsCenterNetbook {
  @media (#{$netbook}) {
    @apply --gridValignCenter;
  }
}
.alignItemsStartNetbook {
  @media (#{$netbook}) {
    @apply --gridValignStart;
  }
}
.alignItemsEndNetbook {
  @media (#{$netbook}) {
    @apply --gridValignEnd;
  }
}

// Laptop:
.alignItemsCenterLaptop {
  @media (#{$laptop}) {
    @apply --gridValignCenter;
  }
}
.alignItemsStartLaptop {
  @media (#{$laptop}) {
    @apply --gridValignStart;
  }
}
.alignItemsEndLaptop {
  @media (#{$laptop}) {
    @apply --gridValignEnd;
  }
}

// Screen:
.alignItemsCenterScreen {
  @media (#{$screen}) {
    @apply --gridValignCenter;
  }
}
.alignItemsStartScreen {
  @media (#{$screen}) {
    @apply --gridValignStart;
  }
}
.alignItemsEndScreen {
  @media (#{$screen}) {
    @apply --gridValignEnd;
  }
}

// Screen:
.alignItemsCenterBigscreen {
  @media (#{$bigscreen}) {
    @apply --gridValignCenter;
  }
}
.alignItemsStartBigscreen {
  @media (#{$bigscreen}) {
    @apply --gridValignStart;
  }
}
.alignItemsEndBigscreen {
  @media (#{$bigscreen}) {
    @apply --gridValignEnd;
  }
}

// HD:
.alignItemsCenterHD {
  @media (#{$HD}) {
    @apply --gridValignCenter;
  }
}
.alignItemsStartHD {
  @media (#{$HD}) {
    @apply --gridValignStart;
  }
}
.alignItemsEndHD {
  @media (#{$HD}) {
    @apply --gridValignEnd;
  }
}

// --------------------------------------------------------------------

// General
.justifyContentCenter {
  @apply --gridHalignCenter;
}
.justifyContentLeft {
  @apply --gridHalignStart;
}
.justifyContentRight {
  @apply --gridHalignEnd;
}
.justifyContentAround {
  @apply --gridHalignAround;
}
.justifyContentBetween {
  @apply --gridHalignBetween;
}

// Mobile
.justifyContentCenterMobile {
  @media (#{$mobile}) {
    @apply --gridHalignCenter;
  }
}
.justifyContentLeftMobile {
  @media (#{$mobile}) {
    @apply --gridHalignStart;
  }
}
.justifyContentRightMobile {
  @media (#{$mobile}) {
    @apply --gridHalignEnd;
  }
}
.justifyContentAroundMobile {
  @media (#{$mobile}) {
    @apply --gridHalignAround;
  }
}
.justifyContentBetweenMobile {
  @media (#{$mobile}) {
    @apply --gridHalignBetween;
  }
}

// Smartphone
.justifyContentCenterSmartphone {
  @media (#{$smartphone}) {
    @apply --gridHalignCenter;
  }
}
.justifyContentLeftSmartphone {
  @media (#{$smartphone}) {
    @apply --gridHalignStart;
  }
}
.justifyContentRightSmartphone {
  @media (#{$smartphone}) {
    @apply --gridHalignEnd;
  }
}
.justifyContentAroundSmartphone {
  @media (#{$smartphone}) {
    @apply --gridHalignAround;
  }
}
.justifyContentBetweenSmartphone {
  @media (#{$smartphone}) {
    @apply --gridHalignBetween;
  }
}

// Tablet:
.justifyContentCenterTablet {
  @media (#{$tablet}) {
    @apply --gridHalignCenter;
  }
}
.justifyContentLeftTablet {
  @media (#{$tablet}) {
    @apply --gridHalignStart;
  }
}
.justifyContentRightTablet {
  @media (#{$tablet}) {
    @apply --gridHalignEnd;
  }
}
.justifyContentAroundTablet {
  @media (#{$tablet}) {
    @apply --gridHalignAround;
  }
}
.justifyContentBetweenTablet {
  @media (#{$tablet}) {
    @apply --gridHalignBetween;
  }
}

// Netbook:
.justifyContentCenterNetbook {
  @media (#{$netbook}) {
    @apply --gridHalignCenter;
  }
}
.justifyContentLeftNetbook {
  @media (#{$netbook}) {
    @apply --gridHalignStart;
  }
}
.justifyContentRightNetbook {
  @media (#{$netbook}) {
    @apply --gridHalignEnd;
  }
}
.justifyContentAroundNetbook {
  @media (#{$netbook}) {
    @apply --gridHalignAround;
  }
}
.justifyContentBetweenNetbook {
  @media (#{$netbook}) {
    @apply --gridHalignBetween;
  }
}

// Laptop:
.justifyContentCenterLaptop {
  @media (#{$laptop}) {
    @apply --gridHalignCenter;
  }
}
.justifyContentLeftLaptop {
  @media (#{$laptop}) {
    @apply --gridHalignStart;
  }
}
.justifyContentRightLaptop {
  @media (#{$laptop}) {
    @apply --gridHalignEnd;
  }
}
.justifyContentAroundLaptop {
  @media (#{$laptop}) {
    @apply --gridHalignAround;
  }
}
.justifyContentBetweenLaptop {
  @media (#{$laptop}) {
    @apply --gridHalignBetween;
  }
}

// Screen:
.justifyContentCenterScreen {
  @media (#{$screen}) {
    @apply --gridHalignCenter;
  }
}
.justifyContentLeftScreen {
  @media (#{$screen}) {
    @apply --gridHalignStart;
  }
}
.justifyContentRightScreen {
  @media (#{$screen}) {
    @apply --gridHalignEnd;
  }
}
.justifyContentAroundScreen {
  @media (#{$screen}) {
    @apply --gridHalignAround;
  }
}
.justifyContentBetweenScreen {
  @media (#{$screen}) {
    @apply --gridHalignBetween;
  }
}

// Screen:
.justifyContentCenterBigscreen {
  @media (#{$bigscreen}) {
    @apply --gridHalignCenter;
  }
}
.justifyContentLeftBigscreen {
  @media (#{$bigscreen}) {
    @apply --gridHalignStart;
  }
}
.justifyContentRightBigscreen {
  @media (#{$bigscreen}) {
    @apply --gridHalignEnd;
  }
}
.justifyContentAroundBigscreen {
  @media (#{$bigscreen}) {
    @apply --gridHalignAround;
  }
}
.justifyContentBetweenBigscreen {
  @media (#{$bigscreen}) {
    @apply --gridHalignBetween;
  }
}

// HD:
.justifyContentCenterHD {
  @media (#{$HD}) {
    @apply --gridHalignCenter;
  }
}
.justifyContentLeftHD {
  @media (#{$HD}) {
    @apply --gridHalignStart;
  }
}
.justifyContentRightHD {
  @media (#{$HD}) {
    @apply --gridHalignEnd;
  }
}
.justifyContentAroundHD {
  @media (#{$HD}) {
    @apply --gridHalignAround;
  }
}
.justifyContentBetweenHD {
  @media (#{$HD}) {
    @apply --gridHalignBetween;
  }
}

// Remove padding from col

// General:
.noPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

// Mobile:
.noPaddingMobile {
  @media (#{$mobile}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.noPaddingMobileMax {
  @media (#{$mobile}Max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Tablet:
.noPaddingSmartphone {
  @media (#{$smartphone}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
// Tablet:
.noPaddingSmartphoneMax {
  @media (#{$smartphone}Max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Tablet:
.noPaddingTablet {
  @media (#{$tablet}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.noPaddingTabletMax {
  @media (#{$tablet}Max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Netbook:
.noPaddingNetbook {
  @media (#{$netbook}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.noPaddingNetbookMax {
  @media (#{$netbook}Max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Laptop:
.noPaddingLaptop {
  @media (#{$laptop}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.noPaddingLaptopMax {
  @media (#{$laptop}Max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Screen:
.noPaddingScreen {
  @media (#{$screen}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.noPaddingScreenMax {
  @media (#{$screen}Max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Screen:
.noPaddingBigscreen {
  @media (#{$bigscreen}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.noPaddingBigscreenMax {
  @media (#{$bigscreen}Max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// HD:
.noPaddingHD {
  @media (#{$HD}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.noPaddingHDMax {
  @media (#{$HD}Max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
