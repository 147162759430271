@import '../../marketplace.module.scss';

.mobileContainerPadding {
  @media (--viewportTabletMax) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.content {
  @media (--viewportSmartphone) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportNetbook) {
    margin: 0;
  }
}

.title {
  hyphens: auto;
  margin-top: 8px;
  margin-bottom: 19px;
  font-size: 30px;

  @media (--viewportSmartphone) {
    margin-bottom: 47px;
    font-size: var(--h1FontSize);
  }
}
