@import '../../marketplace.module.scss';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ManageListingCard_coverEverything: {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.menuOverlayWrapper {
  /* Positioning */
  @apply --ManageListingCard_coverEverything;
}

.menuOverlay {
  /* Positioning */
  @apply --ManageListingCard_coverEverything;

  /* Overlay */
  background-color: var(--matterColorAnti);
  mix-blend-mode: multiply;
  opacity: 0;
  transition: var(--transitionStyleButton);
}

.menuOverlayContent {
  /* Positioning */
  @apply --ManageListingCard_coverEverything;
  /* Fonts */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;

  @media (--viewportSmartphone) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  margin-bottom: 1px; /* Fix 1px bug */

  @media (--viewportSmartphone) {
    padding-bottom: 45.6667%;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  @apply --ManageListingCard_coverEverything;
  width: 100%;
}

.menubarWrapper {
  /* Position */
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
}

.menubarGradient {
  /* Position */
  //position: relative;
  //top: 0;
  //left: 0;
  //width: 50%;
  //height: 100%;

  /* Gradient */
  //background-image: url('./gradient.png');
  //background-repeat: repeat;
  //opacity: 0.5;
}

.menubar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.menuLabel {
  padding: 0px 9px 0 8px;
}

.iconWrapper {
  padding: 2px 10px 0px 10px;
  color: var(--matterColorLight);
  border-radius: 10px;
  transition: var(--transitionStyleButton);
  border: 1px solid var(--matterColorInactive);

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);

    svg {
      fill: var(--matterColorLight);
    }
  }

  svg {
    fill: var(--matterColorInactive);
  }
}

.listingMenuIsOpen {
  & .iconWrapper {
    background-color: rgba(0, 0, 0, 0.25);
    color: var(--matterColorLight);
  }
}

.menuContent {
  position: absolute;
  right: 12px !important;
  top: 50px;
  z-index: var(--zIndexPopup);

  background-color: var(--matterColor);
  border-radius: 30px;
  box-shadow: var(--boxShadowPopup);
}

.menuItem {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  white-space: nowrap;
  text-align: left;
  padding: 8px 14px;
  border-radius: 30px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    background-color: var(--failColor);
  }

  @media (--viewportSmartphone) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.menuItemDisabled {
  color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorNegative);
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 8px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-shrink: 0;
  width: 100%;
}

.priceValue {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);

  margin-right: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportSmartphone) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media (--viewportSmartphone) {
  .perUnit {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.noPrice {
  composes: perUnit;
  padding: 5px 0 3px 0;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.titleWrapper {
}

.title {
  /* Font */
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  text-align: left;
  font-size: 19px;
  font-weight: 300;
  line-height: 24px;
  color: var(--matterColorDark);
  height: 56px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportSmartphone) {
    padding-top: 4px;
    padding-bottom: 4px;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.titlePending {
  color: var(--attentionColor);
}

.titleDraft {
  margin-right: 8px;
}

/* Solid gray background for draft listings without image */
.draftNoImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--matterColor);
}

.edit {
  @apply --marketplaceButtonStylesSecondary;

  /* TODO: Odd font-size */
  font-size: 14px;

  /* Reset min-height from button styles */
  min-height: 0;

  /* Reserve space for button */
  flex-shrink: 0;
  width: auto;
  height: 41px;
  padding: 7px 14px 7px 35px;

  /* Add edit icon as a background image */
  background-image: url('./edit-icon.svg');
  background-repeat: no-repeat;
  background-position: 15px center;
}

.openListingButton,
.finishListingDraftLink {
  @apply --marketplaceButtonStylesPrimary;
  @apply --marketplaceH5FontStyles;

  /* Reset min-height from button styles */
  min-height: 0;

  width: 250px;
  padding: 8px;
}

.menu {
  display: none;
}

.cardIsOpen {
  display: block;
}

.manageLinks {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  line-height: 18px;
  padding-top: 2px;
  padding-bottom: 4px;
  margin-top: 16px;

  /* Remove default margins from font */
  margin-bottom: 0;

  @media (--viewportSmartphone) {
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;

    margin-bottom: 0;
  }
}

.manageLink {
  text-decoration: none;
  color: var(--matterColor);
  margin-bottom: 10px;
  display: inline-block;

  @media (--viewportSmartphone) {
    display: inline;
    white-space: pre;
    margin-bottom: 0;
  }

  span {
    margin-left: 7px;
  }

  &:hover {
    color: var(--matterColorDark);
  }
}

.manageLink:first-child {
  margin-right: 24px;
}

.manageLinksSeparator {
  margin: 0 3px 0 3px;
}

.contentContainer {
  background-color: var(--matterColorLight);
  padding: 13px 15px 15px 15px;
  border-radius: 10px;
  margin-top: -10px;

  .linkText {
    font-size: 19px;
    line-height: 27px;
    letter-spacing: -0.3px;
    color: var(--matterColorDark);
  }

  .priceContainer {
    max-width: 33%;
    text-align: right;
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;

    .currency {
      font-size: 11px;
      font-weight: 300;
      line-height: 20px;
      margin-right: 5px;
      display: inline-block;
      color: var(--matterColorDark);
    }

    .price {
      font-size: 22px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: -0.125px;
      display: inline-block;
      color: var(--matterColorDark);
    }

    .priceText {
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: -0.1px;
      color: var(--matterColorInactive);
      display: inline-block;
    }
  }
}

.priceCampStyleContainer {
  display: flex;
  align-items: center;
  padding: 10px 0 5px 0;
  height: 65px;
}

.cardIconContainer {
  max-width: 66%;
  width: 100%;
}

.iconContainer {
  margin-bottom: 0;
  height: 100%;
  display: flex;
  justify-items: flex-start;

  img {
    display: inline-block !important;
    margin-right: 15px;

    @media (--viewportSmartphone) {
      margin-right: 30px;
    }
  }

  img:last-child {
    margin-right: 0;
  }

  svg {
    margin-right: 15px;
  }

  .verifiedIcon,
  .instantBookingIcon {
    width: 25px;
    height: 100%;
  }

  .carIcon,
  .camperIcon,
  .tentIcon,
  .caravanIcon,
  .hutIcon {
    svg {
      height: 100%;
    }
  }

  .carIcon {
    svg {
      width: 40px;
    }
  }

  .caravanIcon {
    svg {
      width: 41px;
    }
  }

  .camperIcon {
    svg {
      width: 40.2px;
    }
  }

  .hutIcon {
    svg {
      width: 40.2px;
    }
  }

  .tentIcon {
    svg {
      width: 32.7px;
    }
  }
}

.onImageIconContainer {
  position: absolute;
  top: 0;
  padding: 20px 15px;
  width: 100%;
  z-index: 1;

  .powerIcon,
  .approvedIcon {
    display: inline-block;
  }

  .powerIcon {
    float: right;
  }
}

.listingCardLineDivider {
  height: 1px;
  width: 100%;
  background-color: var(--matterColorInactive);
  margin: 18px 0;
}

.stateEditContainer {
  display: flex;
  align-items: center;

  .stateButton {
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
    height: auto;
    white-space: nowrap;
  }

  .stateDraft {
    color: var(--matterColorInactive);
    border: 1px solid var(--matterColorInactive);
  }

  .statePublished {
    color: var(--successColor);
    border: 1px solid var(--successColor);
  }

  .statePending {
    color: var(--attentionColor);
    border: 1px solid var(--attentionColor);
  }

  .stateClosed {
    color: var(--failColor);
    border: 1px solid var(--failColor);
  }
}
