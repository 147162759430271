@import './assets/sanitize.scss';
@import './marketplace.module.scss';

/* ================ Fonts ================ */

body {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: var(--backgroundColor);
}

::selection {
  background: var(--marketplaceColor); /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor); /* Gecko Browsers */
  color: var(--matterColorLight);
}

a {
  color: inherit;
  text-decoration: inherit;
}

/* ================ Normalisations ================ */

html {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

legend,
label {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 0;
  margin-bottom: 0;
}

button {
  font-family: Helvetica, Arial, sans-serif;
}

select {
  @apply --marketplaceSelectStyles;
  background-image: url('./assets/svg/select-arrow-down.svg');
}

input {
  @apply --marketplaceInputStyles;
}

textarea {
  @apply --marketplaceInputStyles;

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  @apply --marketplaceDefaultFontStyles;

  box-sizing: content-box;
  padding: 5px 0 5px 0;
  min-height: 24px;

  &::placeholder {
    padding: 0 0 10px 0;
  }
}

:global(.fontsLoaded) {
  & body {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
  }
  & button {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
  }
}
