@import '../../marketplace.module.scss';

.root {
  position: relative;
}

.mainContainer {
  padding-bottom: 85px;
}

.txInfo {
  margin-bottom: 150px;

  @media (--viewportTablet) {
    margin-top: 60px;
  }

  @media (--viewportNetbook) {
    flex-basis: 538px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 120px;
    margin-bottom: 0;
  }

  @media (--viewportScreen) {
    margin-top: 120px;
  }

  @media (--viewportBigscreen) {
    margin-top: 180px;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportSmartphone) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportNetbook) {
    margin: 32px 0 0 0;
  }
}

.listingCardDesktop {
  display: none;

  @media (--viewportNetbook) {
    display: block;
    margin: 0;
    width: 100%;
  }
}

.detailCard {
  display: grid;
  grid-auto-flow: row;
  row-gap: 20px;
  width: min-content;
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportNetbook) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportNetbook) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportSmartphone) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportNetbook) {
    margin-left: 15px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportNetbook) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportNetbook) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--viewportNetbook) {
    display: block;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  @apply --marketplaceH2FontStyles;
  margin: 29px 24px 0 24px;

  font-size: 25px;
  line-height: 40px;

  a {
    color: var(--nomadyRed) !important;
  }

  @media (--viewportSmartphone) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportNetbook) {
    @apply --marketplaceH2FontStyles;
    max-width: 100%;
    margin: 120px 0 0 0;
    padding: 0;
  }

  @media (--viewportBigscreen) {
    margin: 180px 0 0 0;
  }
}

.headingSale {
  margin: 18px 0 0 0;
  padding: 5px 0 1px 0;
  font-size: 25px;
  line-height: 35px;

  @media (--viewportSmartphone) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportTablet) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (--viewportNetbook) {
    max-width: 100%;
    margin: 42px 15px 0 0;
    padding: 0;
  }

  @media (--viewportUltrascreen) {
    margin: 42px 240px 0 0;
  }
}

.mainTitle {
  display: inline;

  a {
    color: var(--nomadyRed);
  }
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportSmartphone) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportNetbook) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 47px;

  @media (--viewportSmartphone) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportNetbook) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  margin: 1px 0 0 0;

  /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */
  @media (--viewportTablet) {
    margin: 60px 0 0 0;
  }

  @media (--viewportScreen) {
    margin: 120px 0 0 0;
  }
}

.detaiCard {
  border: none;
}

.bookingPanel,
.breakdownContainerMobile,
.breakdownContainer {
  position: relative;
  background-color: #ffffff;
  border-radius: 15px;
  margin: 0;
  padding: 30px 15px;

  &:before {
    content: '';
    display: block;
    height: 1px;
    background-color: #e6e6e6;
  }
}

@media (--viewportLaptopMax) {
  .bookingPanel {
    padding: 0;
  }
}

.content {
    flex-direction: column-reverse;

  @media (--viewportNetbook) {
    flex-direction: initial;
  }
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportNetbook) {
    margin: 32px 15px 10px 15px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 14px 24px 0 24px;

  @media (--viewportSmartphone) {
    margin: 18px 15px 0 15px;
  }

  @media (--viewportNetbook) {
    margin: 0;
    padding: 0;
  }
}

.breakdownContainer {
    display: block;
    padding: 30px 15px;
    margin: 0;
}

/* DetailCardInfoMaybe subcomponent */
.detailCardInfo {
  background-color: var(--marketplaceColorDark);
  padding: 30px 0 30px 0;
  color: #fff;

  @media (--viewportNetbook) {
    padding: 85px 0 50px 0;
  }
}

.detailCardInfo__subtitle {
  opacity: 0.5;
  margin: 15px 0;
  font-weight: var(--fontWeightLight);
}

.detailCardInfo__lockIcon {
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;

  svg {
    width: 24px;
    height: 32px;
    margin-bottom: 7px;
  }
}

.detailCardInfo__addressLink,
.detailCardInfo__addressLink a {
  color: inherit;
  text-decoration: underline;
  margin: 0;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.detailCardInfo__columnHeadings {
  font-weight: var(--fontWeightSemiBold);
  margin-bottom: 10px;
}

.detailCardInfo__columnValues {
  font-weight: var(--fontWeightLight);
  margin-bottom: 10px;
}

.detailCardInfo__phoneNumber {
  color: #fff;
  text-decoration: underline;
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--viewportNetbook) {
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.detailCardSubtitle {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportNetbook) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportSmartphone) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportNetbook) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportSmartphone) {
    margin: 40px 24px 0 24px;
  }
  @media (--viewportNetbook) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportSmartphone) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportNetbook) {
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportSmartphone) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportNetbook) {
    margin: 47px 0 0 0;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportNetbook) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportNetbook) {
    display: block;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 15px;
    background-color: #ffffff;
  }
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}

.afterBookingInfoParagraph {
  margin-bottom: 10px;
}

.linkToDetailCard {
  display: block;
  background-color: var(--marketplaceColorDark);
  width: 100%;
  height: 40px;
  max-width: 382px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin: auto;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.11px;
  padding: 0;

  &:hover {
    text-decoration: none;
  }

  span {
    line-height: 40px;
    height: 40px;
    margin: 0 5px;
    vertical-align: middle;

    svg {
      width: 7px;
      height: 4px;
    }
  }
}

.detailCardMap {
  display: flex;
  justify-content: center;
  background-color: var(--marketplaceColorDark);
  padding-bottom: 60px;
}
