@import '../../marketplace.module.scss';

.root {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  align-items: flex-start;
}

.loading {
  margin-left: 24px;
  margin-right: 24px;
}

.error {
  margin-left: 24px;
  margin-right: 24px;
  color: var(--failColor);
}

.tabContent {
  display: none;
}

.tabContentVisible {
  display: block;
}
